import React from 'react'
import style from './Contact.module.scss'

export default function Contact() {
  return (
    <>
    <div className={`${style.contact} p-5`}>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
          <div className={`${style.contactTitle}`}>
          <h2 className='py-5'>Message Us</h2>
          </div>
          <form>
                  <div class="mb-3">
                    <input
                      type="text"
                      class="form-control"
                      name="name"
                      placeholder="Your Name"
                    />
                  </div>
                  <div class="mb-3">
                    <input
                      type="email"
                      class="form-control"
                      name="email"
                      placeholder="Your Email"
                    />
                  </div>
                  <div class="mb-3">
                    <input
                      type="text"
                      class="form-control"
                      name="subject"
                      placeholder="Your Subject"
                    />
                  </div>
                  <div class="mb-3">
                    <textarea
                      name="message"
                      class="form-control"
                      placeholder="Message"
                      cols="20"
                      rows="5"
                    ></textarea>
                  </div>
                  <div class="mb-3 text-center mt-4">
                    <button type="submit" class={`${style.myBtn} btn btn-primary`}>
                      Send Message
                    </button>
                  </div>
                </form>

          </div>
          <div className="col-md-6">
          <div className={`${style.contactTitle}`}>
          <h2 className='py-5'>Get in Touch</h2>
          </div>
          <p className='mx-3'> Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Facilis dolorum dolorem soluta quidem expedita aperiam aliquid
                  at. Totam magni ipsum suscipit amet? Autem nemo esse
                  laboriosam ratione nobis mollitia inventore?</p>
          <ul className={`${style.contactlinks}`}>
          <li>
                    <i className="fa-solid fa-location-dot"></i>329 WASHINGTON ST
                    BOSTON, MA 02108
                  </li>
                  <li>
                    <i className="fa-solid fa-mobile-screen-button"></i>(617)
                    557-0089
                  </li>
                  <li>
                    <i className="fa-solid fa-envelope"></i>contact@example.com
                  </li>
          </ul>
          <ul className={`${style.social}`}>
          <li><i className="fa-brands fa-facebook"></i></li>
                  <li><i className="fa-brands fa-twitter"></i></li>
                  <li><i className="fa-brands fa-instagram"></i></li>
                  <li><i className="fa-brands fa-youtube"></i></li>
          </ul>

        

          </div>
        </div>

      </div>
    </div>
    
    </>
  )
}
