import React from 'react'
import { Link } from 'react-router-dom'
import style from './Home.module.scss'


export default function Home() {
  return (
    <>
    <div className={`${style.home}`}>
            <div className={`${style.overlay}`}></div>
        <div className={`${style.cont} container position-relative`}>
            <div className={`${style.info}`}>
                <h2 className={`${style.title}`}>Welcome To ae 7aga....</h2>
                <Link className={`${style.myBtn} btn btn-danger my-3`} to="register">Join Us Now!</Link>
            </div>
        </div>
    </div>

    <div className={`${style.about} py-5`}>
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    <div>
                        <h2 className={`${style.aboutTitle}`}>About Us</h2>
                        <p className={`${style.aboutpara} my-4 mx-4 text-center `}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident quidem ipsam blanditiis quo quia dolorum maiores, inventore quas sint numquam consectetur aliquam deserunt, molestiae deleniti in maxime. Ut, a architecto!</p>
                        <div className='text-center'>
                        <Link className={`${style.myBtn} btn btn-danger mx-5 my-3`} to="register">Join Us Now!</Link>
                        </div>
                        
                    </div>
                </div>
                <div className="col-md-6">
                    <div>
                        <img src="./images/4.jpg" alt="" className='w-100' />
                    </div>
                </div>
            </div>
        </div>

    </div>
 

    </>
  )
}
