import React, { useState } from 'react'
import style from './Register.module.scss'
// import axios from "axios";
import Joi from "joi";





export default function Register() {

  const [user, SetUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone:"",
    grade: "",
    language: "",
  });
  //onchange
  let inputData = (e)=>{
    let myUser= {...user}
    myUser[e.target.name] = e.target.value
    SetUser(myUser);
    console.log(myUser);
  }
  //joi validation
  let validationForm = ()=>{
    let schema = Joi.object({
      first_name:Joi.string().required().alphanum().min(4).max(4),
      last_name:Joi.string().required().alphanum().min(4).max(4),
      email:Joi.string().required().email({ tlds: { allow: ["com", "net"] } }),
      phone:Joi.number().required().min(11).max(11),
      grade: Joi.array().items(Joi.string()),
      language: Joi.array().items(Joi.string()),

    })
    return schema.validate(user)
  }
  //onsumbit
  let validForm = (e)=>{
    e.preventDefault()
    let validMyForm = validationForm ()
    if(validMyForm.error){
      alert('we7sh')
    }
    else{
     alert('tamam')
  }}
  return (
   <>
   <div className={`${style.reg}`}>
    <div className="container">
    <div className={`${style.info} py-3 mb-2`}>
                <h4 className=" m-3 ">Join Us!</h4>

                <form onSubmit={validForm} >
                  <div className="row">
                    <div className="col-lg-6 my-3">
                      <div className={`${style.inputData}  `}>
                        <input
                         onChange={inputData }
                          type="text"
                          name="first_name"
                          className="form-control "
                          placeholder="First Name"
                        />
                       
                      </div>
                    </div>
                    <div className="col-lg-6 my-3">
                      <div className={`${style.inputData}  `}>
                        <input
                         onChange={inputData }
                          type="text"
                          name="last_name"
                          className="form-control "
                          placeholder="Last Name"
                        />
                    
                      </div>
                    </div>
                  </div>
                  <div className={`${style.inputData} my-4 `}>
                    <input
                 onChange={inputData }
                      type="email"
                      name="email"
                      className="form-control my-3"
                      placeholder="Email"
                    />
              
                
                  </div>
                  <div className={`${style.inputData} my-4 `}>
                    <input
                 onChange={inputData }
                      type="number"
                      name="phone"
                      className="form-control my-3"
                      placeholder="Phone Number"
                    />
              
                
                  </div>
                  <div className={`${style.inputData} my-4 `}>
                  <select className="form-select my-3" aria-label="Default select example" onChange={inputData } name="language">
                    <option value="DEFAULT">Select Language</option>
                     <option value="arabic" name="language">Arabic</option>
                       <option value="math" name="language">Math</option>
                         <option value="science" name="language">Science</option>
                            </select>
                 
                  </div>
                  <div className={`${style.inputData} my-4 `}>
                  <select className="form-select my-3" aria-label="Default select example" name="grade" onChange={inputData }>
                    <option value="DEFAULT">Select Your Grade</option>
                     <option value="primary" name="grade">Primary</option>
                       <option value="prep" name="grade">Prep</option>
                         <option value="secondary" name="grade">Secondary</option>
                            </select>
                  </div>
                  <div className={`${style.inputData} my-3 text-center`}>
                    <button className={`${style.myBtn} btn btn-danger `}>
                      Join
                    </button>
                  </div>
                </form>
        
          
              </div>

    </div>

   </div>

    </>
  
  )
}
