import React from 'react'
import { Link } from 'react-router-dom'
import style from './About.module.scss'

export default function About() {
  return (
//    <div className="about" id="about">
//   <div className="container-about">
//     <div className="item">
//       <div className="item-border">
//         <div className="img">
//           <div className="item-overlay">
//             <ul>
//               <li><i className="fa-brands fa-facebook-f" /></li>
//               <li><i className="fa-brands fa-twitter" /></li>
//               <li><i className="fa-brands fa-instagram" /></li>
//               <li><i className="fa-brands fa-google" /></li>
//             </ul>
//           </div>
//         </div>
//       </div>
//     </div>
//     <div className="about-title">
//       <h2>HELLO,</h2>
//       <p>
//         Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
//         eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
//         ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
//         aliquip ex ea commodo consequat. Duis aute irure dolor in
//         reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
//         pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
//         culpa qui officia deserunt mollit anim id est laborum.
//       </p>
//       <div className="about-border" />
//       <div className="list">
//         <ul className="one">
//           <li><span>Name:</span> Rdam smith</li>
//           <li><span>Age:</span> 26 Year</li>
//           <li><span>Freelance:</span> Available</li>
//         </ul>
//         <ul className="two">
//           <li><span>Job Title:</span> UI/UX Designer</li>
//           <li><span>Location:</span> United States, US</li>
//           <li><span>E-mail:</span> Nadia420@gmail.com</li>
//         </ul>
//         <div className="clear-fix" />
//       </div>
//       <button className="btn">
//         Download CV <i className="fa fa-download" />
//       </button>
//       <button className="btn">Hire Me <i className="fa fa-paper-plane" /></button>
//     </div>
//     <div className="clear-fix" />
//   </div>
// </div>


<div className={`${style.about}`}>
  <div className="container">
    <div className="row">
      <div className="col-md-4">
    <div className={`${style.item}`}>
    <div className={`${style.itemBorder}`}>
    <div className={`${style.img}`}>
          <div className= {`${style.overlay}`}>
            <ul>
              <li><i className="fa-brands fa-facebook-f" /></li>
              <li><i className="fa-brands fa-twitter" /></li>
              <li><i className="fa-brands fa-instagram" /></li>
              <li><i className="fa-brands fa-google" /></li>
            </ul>
          </div>
        </div>

    </div>
    </div>
      </div>
      <div className="col-md-8">
        <div className={`${style.aboutTitle} mx-5`}>
        <h2 className='mb-5'>HELLO,</h2>
        <p className='mb-5'>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
       eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
      reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
       culpa qui officia deserunt mollit anim id est laborum.
     </p>
     <div className={`${style.aboutBorder} `}>
      <div className={`${style.list}`}>
        <div className="row">
          <div className="col-md-6">
        <ul className={`${style.one}`}>
        <li><span>Name:</span> Hussien</li>
       <li><span>Age:</span> 26 Year</li>
          <li><span>Lessons:</span> Available</li>

        </ul>
      
          </div>
          <div className="col-md-6">
        <ul className={`${style.two}`}>
        <li><span>Job Title:</span> Arabic Teacher</li>
       <li><span>Location:</span> Alex, Egy</li>
        <li><span>E-mail:</span> Hussien@gmail.com</li>
        </ul>
          </div> 
      <Link className={`${style.myBtn} btn btn-danger my-5`} to="register">Join Us <i className="fa fa-paper-plane" /></Link>
        </div>
       

       

      </div>

     </div>

        </div>

      </div>
    </div>
  </div>

</div>

  )
}
