import {  createHashRouter, RouterProvider } from "react-router-dom";
import About from "../About/About";
import Contact from "../Contact/Contact";
import Home from "../Home/Home";
import MasterLayout from "../MasterLayout/MasterLayout";
import Register from "../Register/Register";


function App() {

let routes = createHashRouter([
  {path:'/',element:<MasterLayout/>,children:[
    {index:true,element:<Home/>},
    {path:'about',element:<About/>},
    {path:'contact',element:<Contact/>},
    {path:'register',element:<Register/>}
  ]}
])





  return (
 <RouterProvider router={routes}/>
  );
}

export default App;
