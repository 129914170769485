import React from 'react'
import { NavLink } from 'react-router-dom'
import style from './Navbar.module.scss'

export default function Navbar() {
  return (
    <>
  <nav className={`${style.mainNav} navbar navbar-expand-lg navbar-dark`}>
  <div className="container">
    <NavLink className={`${style.brand} navbar-brand`} >Lessons</NavLink>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon" />
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
        <li className="nav-item">
      
         <NavLink className={({isActive})=>isActive?" nav-link yellowbg":"nav-link"} to="">Home</NavLink>
        </li>
        <li className="nav-item">
        <NavLink className={({isActive})=>isActive?" nav-link yellowbg":"nav-link"} to="about">About</NavLink>
        </li>
        <li className="nav-item">
        <NavLink className={({isActive})=>isActive?" nav-link yellowbg":"nav-link"} to="register">Register</NavLink>
        </li>
        <li className="nav-item">
        <NavLink className={({isActive})=>isActive?" nav-link yellowbg":"nav-link"} to="contact">Conatct</NavLink>
        </li>
      </ul>
    </div>
  </div>
</nav>

    </>
  )
}
