import React from 'react'
import { Link } from 'react-router-dom'
import style from './Footer.module.scss'

export default function Footer() {
  return (
   <>
   <footer className={`${style.footer}`}>
      <section className={`${style.headfooter} d-flex justify-content-between p-4`}>
      <div className="me-5">
        <span>Get connected with us on social networks:</span>
      </div>
        <div>
        <a href="/" className="text-white me-4">
          <i className="fab fa-facebook-f"></i>
        </a>
        <a href="/" className="text-white me-4">
          <i className="fab fa-twitter"></i>
        </a>
        <a href="/" className="text-white me-4">
          <i className="fab fa-google"></i>
        </a>
        <a href="/" className="text-white me-4">
          <i className="fab fa-instagram"></i>
        </a>
        <a href="/" className="text-white me-4">
          <i className="fab fa-linkedin"></i>
        </a>
        <a href="/" className="text-white me-4">
          <i className="fab fa-github"></i>
        </a>
      </div>

   
      </section>



      <section className="">
      <div className="container text-center text-md-start mt-5">
     
        <div className="row mt-3">
      
          <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
          
            <h6 className="text-uppercase fw-bold">Lessons</h6>
            <hr
                className="mb-4 mt-0 d-inline-block mx-auto"/>
            <p>
              Here you can use rows and columns to organize your footer
              content. Lorem ipsum dolor sit amet, consectetur adipisicing
              elit.
            </p>
          </div>
       
          <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
         
            <h6 className="text-uppercase fw-bold">Location</h6>
            <hr
                className="mb-4 mt-0 d-inline-block mx-auto"/>

          </div>
         
          <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
         
            <h6 className="text-uppercase fw-bold">Useful links</h6>
            <hr
                className="mb-4 mt-0 d-inline-block mx-auto"/>
            <p>
              <Link to="about" className="text-white">About</Link>
            </p>
            <p>
              <Link to="register"  className="text-white">Register</Link>
            </p>
            <p>
              <Link to="contact" className="text-white">Contact</Link>
            </p>
          </div>
      

         
          <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
         
            <h6 className="text-uppercase fw-bold">Contact</h6>
          <hr className="mb-4 mt-0 d-inline-block mx-auto"  />

            <p><i className="fas fa-home mr-3"></i> New York, NY 10012, US</p>
            <p><i className="fas fa-envelope mr-3"></i> info@example.com</p>
            <p><i className="fas fa-phone mr-3"></i> + 01 234 567 88</p>
            <p><i className="fas fa-print mr-3"></i> + 01 234 567 89</p>
          </div>
       
        </div>
        
      </div>
    </section>
   
    <div className={`${style.copy} text-center p-3`}>
      © 2022 Copyright:  <span> Ahmed Shebl</span>
    </div>
  
  </footer>
  
















   
   </>
  )
}
